import React from 'react';
import Menu from '../../components/Menu/Menu';
function Despezas() {

    return (
        <React.Fragment>
            <Menu />        
            <main className='content'>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                    <div className='d-block mb-4 mb-md-0'>
                        <h1 className='h4'>Despezas</h1>
                    </div>
                </div>                
            </main>
        </React.Fragment>
    );
}

export default Despezas;