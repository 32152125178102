import React, {useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import barraApresentacao from '../barraApresentacao/barraApresentacao';
import { Chart } from "react-google-charts";
import { getDoadores, getDoadoresAptosInaptos, getDoadoresUnidade } from '../../services/DoadoresService';
import SelectCliente from '../../components/SelectCliente/SelectCliente';

export const dataPie = [
    ["Task", "Hours per Day"],
    ["DEA", 200],
    ["CHV", 41],
    ["CHROMOSVET", 95],
    ["HEMOCARE", 71]
    
  ];

  
export const optionsPie = {
    title: "Total de Doadores por unidade",
  };

  export const dataPieEspecie = [
    

    ["Espécie", "QTDE"],
    ["Canino", 15],
    ["Felino", 15],
    ["Equino", 12],
    ["Avícola", 6]
    
  ];

  
export const optionsPieEspecie = {
    title: "Total de Doadores por Espécie",
  };

  export const dataBolsas = [
    ["Unidades", "Bolsas"],
    ["DEA", 431],
    ["CHV", 153],
    ["CHROMOSVET", 84],
    ["HEMOCARE", 107],
  ];

  export const optionsBolsas = {
    chart: {
      title: "Bolsas Disponíveis em Estoque",
      subtitle: "Quantitativo de Bolsas Disponiveis em Estoque por Unidades",
    },
  };
    
export const data = [
  ["Unidades", "Aptos", "Inaptos"],
  ["DEA", 1000, 400],
  ["CHV", 1170, 460],
  ["CHROMOSVET", 660, 1120],
  ["HEMOCARE", 1030, 540],
];

export const options = {
  chart: {
    title: "APTOS/INAPTOS",
    subtitle: "Quantitativo de APTOS/INAPTOS por Unidades",
  },
};

function Dashboard() {

    
    const [doadores, setDoadores] = useState({});
    const [doadoresUnidade, setDoadoresUnidade] = useState({});
    const [doadoresAptosInaptos, setDoadoresAptosInaptos] = useState({});

    const [notification, setNotification] = useState({});
    const pieEspecieTeste = [
        ["Espécie", "QTDE"],
        ["Canino", doadores.canino],
        ["Felino", doadores.felino],
        ["Equino", doadores.equino],
        ["Avícola", doadores.avicola]
    ]

    const pieUnidades = [
        ["Unidade", "QTDE"],
        ["DEA", doadoresUnidade.dea],
        ["CHV", doadoresUnidade.chv],
        ["CHROMOSVET", doadoresUnidade.chromosvet],
        ["HEMOV", doadoresUnidade.hemov]  
    ]

    const aptosInaptosData = [
        ["Unidades", "Aptos", "Inaptos"],
        ["DEA", doadoresAptosInaptos.deaAptos, doadoresAptosInaptos.deaInaptos],
        ["CHROMOSVET", doadoresAptosInaptos.chroAptos, doadoresAptosInaptos.chroInaptos],
        ["HEMOCARE", doadoresAptosInaptos.hemAptos, doadoresAptosInaptos.hemInaptos],
      ];
    

    
    useEffect(() => {
        const token = localStorage.getItem("token");

        getDoadores(token)
            .then(result => setDoadores(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
        
        getDoadoresUnidade(token)
        .then(result => setDoadoresUnidade(result))
        .catch(err => {
            console.error(err.response ? err.response.data : err.message);
            setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
        })

        getDoadoresAptosInaptos(token)
        .then(result => setDoadoresAptosInaptos(result))
        .catch(err => {
            console.error(err.response ? err.response.data : err.message);
            setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
        })
    }, []);

    return (
        <React.Fragment>
            <Menu /> 
            <div>
            <barraApresentacao userName="Willian" /> 
            </div>
            <main className='content'>
            <div className="card card-body border-2 mb-1 mt-2 shadow table-wrapper table-responsive">
                    <div className='text-center h4'>Dashboard</div>
            </div>
            <div className="card card-body border-2 mb-1 mt-2 shadow table-wrapper table-responsive">
                    <div className='Row'>
                        <div className='h4'>Cliente</div>
                        <div className='h4'><SelectCliente /></div>
                    </div>
            </div>
            
                <div class="row mb-4">
                    <div class="col-sm-3">
                        <div class="card  text-white bg-primary">
                            <div class="card-body">
                                <h5 class="card-title">Total Doadores Caninos</h5>
                                <p class="card-text">{doadores.canino}</p>
                            </div>
                        </div>
                    </div>                                        
                    <div class="col-sm-3">
                        <div class="card text-white bg-info">
                            <div class="card-body">
                                <h5 class="card-title">Total Doadores Felinos</h5>
                                <p class="card-text">{doadores.felino}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card text-white bg-secondary">
                            <div class="card-body">
                                <h5 class="card-title">Total Doadores Equinos</h5>
                                <p class="card-text">{doadores.equino}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card text-white bg-success">
                            <div class="card-body">
                                <h5 class="card-title">Total Doadores Avicola</h5>
                                <p class="card-text">{doadores.avicola}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row mb-4 ml-0 mr-0">
                    <div class="col-sm-6">
                        <Chart
                            chartType="PieChart"
                            data={pieUnidades}
                            options={optionsPie}
                            width={"100%"}
                            height={"350px"}
                        />
                    </div>
                    <div class="col-sm-6">
                        <Chart
                            chartType="PieChart"
                            data={pieEspecieTeste} 
                            options={optionsPieEspecie}
                            width={"100%"}
                            height={"350px"}
                        />
                    </div>
                
                </div>
                
                <div className="row mb-4">
                    <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={aptosInaptosData}
                        options={options}
                    />
                </div>
                
                <div className="row">
                    <Chart
                        chartType="Bar"
                        width="100%"
                        height="400px"
                        data={dataBolsas}
                        options={optionsBolsas}
                    />
                </div>

            </main>
        </React.Fragment>
    );
}

export default Dashboard;