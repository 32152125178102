import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function getInatposTriagem(token) {
    const getInaptosTriagem = `${API_URL}/getInaptosTriagem`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(getInaptosTriagem, { headers });
    return response.data;
}