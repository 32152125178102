import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function getDoadores(token) {
    const getDoadores = `${API_URL}/doadoresAptosGeral`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(getDoadores, { headers });
    return response.data;
}

export async function getDoadoresUnidade(token) {
    const getDoadoresUnidades = `${API_URL}/doadoresUnidades`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(getDoadoresUnidades, { headers });
    return response.data;
}

export async function getDoadoresAptosInaptos(token) {
    const getDoadoresAptosInaptos = `${API_URL}/doadoresAptosInaptos`;
    const headers = {
        'authorization': token
    }
    const response = await axios.get(getDoadoresAptosInaptos, { headers });
    return response.data;
}