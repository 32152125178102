import React from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import Login from './public/Login/Login';
import Settings from './private/Settings/Settings';
import Dashboard from './private/Dashboard/Dashboard';
import Atendimentos from './private/Atendimentos/Atendimentos';
import Clientes from './private/Clientes/Clientes';
import Colaboradores from './private/Colaboradores/Colaboradores';
import Produtos from './private/Produtos/Produtos';
import Servicos from './private/Servicos/Servicos';
import TipoDespezas from './private/TipoDespezas/TipoDespezas';
import Despezas from './private/Despezas/Despezas';
import TipoPagamentos from './private/TipoPagamentos/TipoPagamentos';
import InaptosTriagem from './private/InaptosTriagem/InaptosTriagem';

function Routes() {

    function PrivateRoute({ children, ...rest }) {
        return (
            <Route {...rest} render={() => {
                return localStorage.getItem("token")
                    ? children
                    : <Redirect to='/' />
            }} />
        )
    }

    return (
        <BrowserRouter>            
            <Route path="/" exact>
                <Login />
            </Route>
            
            <PrivateRoute path="/configuracao">
                <Settings />
            </PrivateRoute>

            <PrivateRoute path="/dashboard">
                <Dashboard />
            </PrivateRoute>

            <PrivateRoute path="/atendimentos">
                <Atendimentos />
            </PrivateRoute>

            <PrivateRoute path="/clientes">
                <Clientes />
            </PrivateRoute>

            <PrivateRoute path="/colaboradores">
                <Colaboradores />
            </PrivateRoute>

            <PrivateRoute path="/produtos">
                <Produtos />
            </PrivateRoute>

            <PrivateRoute path="/servicos">
                <Servicos />                
            </PrivateRoute>

            <PrivateRoute path="/tipoDespezas">
                <TipoDespezas />            
            </PrivateRoute>

            <PrivateRoute path="/despezas">
                <Despezas />            
            </PrivateRoute>
            
            <PrivateRoute path="/tipoPagamentos">
                <TipoPagamentos />            
            </PrivateRoute>

            <PrivateRoute path="/DEA+">
                <InaptosTriagem />            
            </PrivateRoute>
        </BrowserRouter>
    )
}

export default Routes;