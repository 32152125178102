import React from 'react';
import Menu from '../../components/Menu/Menu';
function Clientes() {

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Clientes</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            
                        </div>
                        <div className="btn-group ms-2 ms-lg-3">
                            
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Nome</th>
                                <th className="border-gray-200">Fone</th>
                                <th className="border-gray-200">Email</th>                                
                                <th className="border-gray-200">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </table>
                    
                </div>
                
            </main>
            
            
            
        </React.Fragment>
    );
}

export default Clientes;