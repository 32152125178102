import React, {useEffect, useState} from 'react';
import Menu from '../../components/Menu/Menu';
import { getUser } from '../../services/SettingsServices';
function Settings() {


    const [usuario, setUsuario] = useState({})
    useEffect(() => {
        const token = localStorage.getItem("token");
        getUser(token)
        .then(result => setUsuario(result))
        .catch(err => {
            console.error(err.response ? err.response.data : err.message);
        })
    }, [])

    return (
        <React.Fragment>
            <Menu />

            <main className='content'>
                <div className="card card-body border-2 mb-1 mt-2 shadow table-wrapper table-responsive">
                    <div className='text-center h4'>Usuário</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Usuário</h2>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input className="form-control" id="email" type="email" placeholder="name@company.com" defaultValue={usuario.email} disabled="readonly" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="username">Nome de Usuário</label>
                                        <input className="form-control" id="username" type="text" placeholder="João da Silva" defaultValue={usuario.username}  />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="password">Nova Senha</label>
                                        <input className="form-control" id="password" type="password" placeholder="Informe sua nova senha" />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <label htmlFor="confirmPassword">Confirme a Senha</label>
                                        <input className="form-control" id="confirmPassword" type="password" placeholder="Confirme sua nova senha" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                                    <div className="col-sm-3">
                                        <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" >Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </main>
        </React.Fragment>
    );
}

export default Settings;