import React, {useState, useEffect } from 'react';
import Menu from '../../components/Menu/Menu';
import { getInatposTriagem } from '../../services/InaptosTriagemService';
import { Chart } from "react-google-charts";
import SelectCliente from '../../components/SelectCliente/SelectCliente';

function InaptosTriagem() {

    
 const data = [
    [
      "Data",
      "DEA+",
      "CHROMOSVET",
      "HEMOV",
      "VETORHEMO",
      "HEMOCARE",
      "Média",
    ],
    ["01/11/2023", 3, 5, 2, 3, 2, 5],
    ["2005/06", 8, 10, 3, 5, 0, 5.2],
    ["2006/07", 2, 15, 4, 8, 10, 7.8]
  ];

  const options = {
    title: "Inptos na Triagem",
    vAxis: { title: "Inaptos" },
    hAxis: { title: "Data" },
    seriesType: "bars",
    series: { 5: { type: "line" } },
  };
    const [dadosTriagem, setDadosTriagem] = useState({});
    const [notification, setNotification] = useState({});
    useEffect(() => {
        const token = localStorage.getItem("token");

        getInatposTriagem(token)
            .then(result => setDadosTriagem(result))
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            })
    }, []);

    
    return (
        <React.Fragment>
            <Menu /> 
            <div>
            <barraApresentacao userName="Willian" /> 
            </div>
            <main className='content'>
            <div className="card card-body border-2 mb-1 mt-2 shadow table-wrapper table-responsive">
                    <div className='text-center h4'>Inaptos na Triagem</div>
            </div>
            <div className="card card-body border-2 mb-1 mt-2 shadow table-wrapper table-responsive">
                    <div className='Row'>
                        <div className='h4'>Cliente</div>
                        <div className='h4'><SelectCliente /></div>
                    </div>
            </div>

            <div className="row mb-4 ml-0 mr-0">
                    <div class="col-sm-6">
                        <Chart
                            chartType="ComboChart"
                            width="100%"
                            height="400px"
                            data={data}
                            options={options}
                        />
                    </div>
                </div>
            </main>
                        
                        
                    
            
        </React.Fragment>
    );
}

export default InaptosTriagem;