import React from 'react';




/**
 * props:
 * username
 */
function BarraApresentacao(props){
    return (<React.Fragment>
             <div className='userProfile'>
                <span>{props.userName}</span>

                </div>  
    </React.Fragment>)
}

export default BarraApresentacao;
