import React, { useState, useEffect } from 'react';

/**
 * props:
 * - coin
 * - onChange
 */
function SelectCliente(props) {

    const [coins, setCoins] = useState([]);


    return (
        <select className="form-select" id="coin" >
            <option value="">Todos</option>  
            <option value="1">DEA</option>  
            <option value="2">CHROMOSVET</option>
            <option value="3">HEMOV</option>
            <option value="4">CHV</option>
            <option value="6">VETORHEMO</option>
            <option value="7">HEMOCARE</option>
        </select>
    )
}

export default SelectCliente;