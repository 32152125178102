import React from 'react';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';

function Atendimentos() {

    return (
        <React.Fragment>
            <Menu />        
            <main className='content'>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
                    <div className='d-block mb-4 mb-md-0'>
                        <h1 className='h4'>Atendimentos</h1>
                    </div>
                </div>                
                <Footer />
            </main>
            
        </React.Fragment>
    );
}

export default Atendimentos;